<script>
import {
    ArrowUpIcon,
    ArrowRightIcon
} from 'vue-feather-icons';

import Navbar from "@/components/navbar";
import Switcher from "@/components/switcher";
import Footer from "@/components/footer";
/**
 * Page-terms component
 */
export default {
    data() {
        return {}
    },
    components: {
        Navbar,
        Switcher,
        Footer,
        ArrowUpIcon,
        ArrowRightIcon
    }
}
</script>

<template>
<div>
    <Navbar />

    <!-- Hero Start -->
    <section class="bg-half bg-light d-table w-100" style="padding-top: 50px; padding-bottom: 50px;">
        <div class="container">
            <div class="row justify-content-center mt-5">
                <div class="col-lg-12 text-center">
                    <div class="page-next-level">
                        <h4 class="title"> Política de Privacidad</h4>
                    </div>
                </div>
                <!--end col-->
            </div>
            <!--end row-->
        </div>
        <!--end container-->
    </section>
    <!--end section-->
    <!-- Hero End -->

    <!-- Shape Start -->
    <div class="position-relative">
        <div class="shape overflow-hidden text-white">
            <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
            </svg>
        </div>
    </div>
    <!--Shape End-->

    <!-- Start Terms & Conditions -->
    <section class="section">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-9">
                    <div class="card shadow border-0 rounded">
                        <div class="card-body">
                            <p class="text-muted">
                                De conformidad en lo dispuesto en la Ley Federal de Protección de Datos Personales en Posesión de los Particulares y sus disposiciones reglamentarias, Hub de Pacientes DPPL, S.A.P.I. de C.V. (en adelante, “Duppla”), con domicilio en: Avenida Lázaro Cárdenas 2400, piso 5, local E1, Col. Del Valle Oriente, San Pedro Garza García, Nuevo León, pone a disposición de los usuarios de la página web <b class="text-primary ">www.duppla.doctor</b>, la presente Política de Privacidad, con la finalidad de ofrecer información sobre cómo tratamos sus datos y protegemos su privacidad e información.

                                “Duppla” solicita al usuario que dedique unos minutos a leer con detenimiento la presente Política de Privacidad.
                            </p>

                            <h5 class="card-title">Introducción</h5>
                            <p class="text-muted">
                                La presente Política de Privacidad resulta de aplicación a la página web puesta a disposición del público en <b class="text-primary ">www.duppla.doctor</b>, así como a las interacciones que el usuario realiza con “Duppla” a través de dicha página web.

                                “Duppla” podrá modificar esta Política de Privacidad cuando resulte necesario. En caso de introducirse modificaciones, se lo comunicaremos a través de la página web o bien por otros medios (por correo electrónico o de forma personal) con la finalidad de que pueda conocer las nuevas condiciones de Privacidad. El hecho de continuar haciendo uso de las funcionalidades puestas a disposición por “Duppla” después de haberle notificado las referidas modificaciones supondrá que está de acuerdo con las mismas, salvo para los supuestos en los que sea necesario su consentimiento expreso.
                            </p>
                            
                            <h5 class="card-title">¿Quién es el responsable del tratamiento de sus datos personales?</h5>
                            <p class="text-muted">
                                El responsable de tratamiento de los datos personales es: Desarrollo de Negocios El Cubo S.A. de C.V. (en adelante, “Duppla”), con domicilio en Miguel Hidalgo Poniente, número 2030, Colonia Obispado, Código Postal 64060, Monterrey, Nuevo León.

                                Para la adecuada gestión en el tratamiento de sus datos personales, “Duppla” tiene designado un Delegado de Protección de Datos Personales a quien podrá dirigirse para resolver cualquier cuestión que precise, pudiendo contactar a través del correo electrónico <a href="mailto:privacidad@duppla.doctor" target="_blank">privacidad@duppla.doctor</a>
                            </p>

                            <h5 class="card-title">¿Con qué finalidad tratamos sus datos?</h5>
                            <p class="text-muted">
                                Los datos personales que sean facilitados por los usuarios de la presente página web serán tratados por “Duppla” con las siguientes finalidades, en función de la tipología de datos que sean facilitados por el usuario, así como en función de las interacciones que el usuario entable con “Duppla” a través de la página web.<br/><br/>
                                
                                <b class="text-primary ">Contacto y gestión de los usuarios del Sitio web: </b>Los datos personales de los usuarios que contactan con “Duppla” a través de cualquiera de los canales de comunicación puestos a disposición a través de la página web serán tratados con la finalidad de gestionar dicho contacto, atender las solicitudes remitidas mediante los canales de contacto y, en su caso, prestar los servicios requeridos por el usuario.<br/><br/>
                                
                                <b class="text-primary ">Solicitud de una segunda opinión médica, u otras solicitudes relacionadas: </b>Los datos personales de los usuarios que contactan con “Duppla” a través de los diferentes canales habilitados en la página web para la solicitud de segundas opiniones u otros servicios relacionados, serán tratados por “Duppla” con la finalidad de gestionar y tramitar la solicitud en cada caso formulada por el usuario.  <br/><br/>

                                En tales supuestos, “Duppla” informa al usuario que, cuando proporcione datos clínicos o de salud de cualquier tipo a través de los citados canales de contacto, tales datos personales serán tratados, exclusivamente, con la finalidad de desarrollar y ejecutar las relaciones jurídicas, contractuales y / o asistenciales entabladas entre el usuario y “Duppla”. Del mismo modo, “Duppla” informa al usuario que aplica medidas técnicas para proteger sus datos personales.<br/><br/>

                                <b class="text-primary ">Acceso al Área de Pacientes: </b>Los datos personales facilitados por el usuario para solicitar su registro en la plataforma serán tratados con la finalidad de posibilitar el registro del usuario en dicha plataforma, así como la posibilidad de acceso a las funcionalidades y gestiones ofrecidas por “Duppla” mediante su plataforma.
                            </p>

                            <h5 class="card-title">¿Qué datos tratamos y de qué fuente se obtienen?</h5>
                            <p class="text-muted">
                                Los datos que “Duppla” trata como consecuencia de las interacciones realizadas por el usuario a través de nuestra página web proceden de las siguientes fuentes:<br/><br/>
                                <ul>
                                    <li>
                                        Datos facilitados por el usuario, a través de la cumplimentación de los formularios de contacto puestos a disposición por “Duppla” en su página web, mediante el envío de correos electrónicos o a través de cualquier otra vía.
                                    </li>
                                    <li>
                                        Datos generados como consecuencia de la navegación y uso por el usuario de la página web de “Duppla”.
                                    </li>
                                    <li>
                                        Datos generados como consecuencia del desarrollo, tramitación y mantenimiento de la relación entablada entre el usuario y “Duppla”.
                                    </li>
                                </ul>

                                “Duppla” podrá tratar datos personales de diferentes tipologías, en función de la relación entablada con el usuario:<br/><br/>

                                <ul>
                                    <li>
                                        <b class="text-primary ">Datos de identificación </b>(nombre, apellidos, correo electrónico, teléfono, dirección, etc.).
                                    </li>
                                    <li>
                                        <b class="text-primary ">Datos de características personales o circunstancias sociales </b>(fecha de nacimiento).
                                    </li>
                                    <li>
                                        <b class="text-primary ">Datos económicos o de transacciones de bienes y servicios </b>(número de cuenta bancaria o de tarjeta bancaria para el pago de servicios o datos de transacciones o pagos realizadas).
                                    </li>
                                    <li>
                                        <b class="text-primary ">Datos de navegación </b>(datos de uso de la página web de “Duppla”).
                                    </li>
                                    <li>
                                        <b class="text-primary ">Datos de salud.</b> Exclusivamente en aquellos casos en los que el usuario proporcione datos de salud a través de los canales habilitados para mantener una relación contractual con el usuario a través de la web de “Duppla”, serán tratados tales datos por “Duppla”, únicamente con la finalidad de desarrollar la relación entablada con el usuario o de gestionar su solicitud, y aplicando las oportunas medidas de seguridad en el tratamiento de tales datos.
                                    </li>
                                </ul>
                            </p>

                            <h5 class="card-title">¿Cuál es la legitimación legal para el tratamiento de sus datos?</h5>
                            <p class="text-muted">
                                “Duppla” trata los datos personales facilitados por los usuarios de su página web de acuerdo con las siguientes bases legales, en función de los datos que sean facilitados por el usuario, así como de las interacciones que el usuario entable con “Duppla”:<br/><br/>
                                <ol>
                                    <li>
                                        El tratamiento de datos para las finalidades “Contacto y gestión de los usuarios del sitio web”, “Plataforma” se basa en la gestión y tramitación de la relación jurídica entablada entre el usuario y “Duppla”, es decir, en la gestión de su propia solicitud, en el mantenimiento del contacto entre las partes y, en su caso, en la prestación del servicio que hubiera solicitado.
                                    </li>
                                    <li>
                                        El tratamiento de datos para las finalidades “Segunda opinión médica”, se basa en la gestión y tramitación de la relación jurídica entablada entre el usuario y “Duppla” al cumplimentar cada uno de los formularios referidos, así como en el consentimiento que el usuario otorga mediante la cumplimentación y envío del formulario de que se trate y, en su caso, de la documentación que acompañe en dicho envío.
                                    </li>
                                </ol>
                            </p>

                            <h5 class="card-title">¿A quién se comunican sus datos?</h5>
                            <p class="text-muted">
                                Los datos personales recabados por “Duppla” para alcanzar las finalidades de tratamiento detalladas anteriormente serán comunicados, exclusivamente para el correcto desarrollo de la relación jurídica, contractual y / o asistencial entablada entre el usuario y “Dupla” y, en su caso, el cumplimiento de obligaciones legales de “Duppla”.
                            </p>

                            <h5 class="card-title">¿Por cuánto tiempo conservaremos sus datos?</h5>
                            <p class="text-muted">
                                “Duppla” conservará sus datos personales durante el tiempo necesario para la prestación del servicio solicitado y / o para alcanzar la finalidad de tratamiento perseguida.<br/>
                                Posteriormente, siempre que el usuario no haya ejercido su derecho de oposición, sus datos serán conservados durante los plazos legales que en cada caso resulten de aplicación, teniendo en cuenta la tipología de datos, así como la finalidad de tratamiento.
                            </p>

                            <h5 class="card-title">¿Qué datos personales debe facilitarnos en cada caso?</h5>
                            <p class="text-muted">
                                “Duppla” informa a los usuarios que, cuando los datos personales sean recabados a través de un formulario puesto a disposición a través de nuestra página web, será necesario que el usuario aporte, al menos, aquellos datos marcados como obligatorios en el formulario de que se trate.<br/>
                                En caso de no suministrar al menos tales datos, considerados como necesarios, “Duppla” no podrá gestionar la prestación del servicio o atender la gestión solicitada por el usuario.
                            </p>

                            <h5 class="card-title">¿Qué debe garantizarnos al aportar sus datos personales?</h5>
                            <p class="text-muted">
                                El usuario garantiza que los datos que aporte son verdaderos, exactos, completos y se encuentran actualizados, siendo responsable de cualquier daño o perjuicio, directo o indirecto, que pudiera ocasionarse como consecuencia del incumplimiento de tal obligación.<br/><br/>

                                En caso de que el usuario aporte datos pertenecientes a un tercero, garantiza que ha informado a dicho tercero de la totalidad de aspectos contenidos en la presente Política de Privacidad y obtenido su consentimiento para facilitarnos sus datos para la finalidad de tratamiento de que se trate. Todo ello, con carácter previo al suministro de datos de un tercero a través de nuestra página web.<br/><br/>

                                “Duppla” le informa que para aportar sus datos personales, de cualquier modo, a través de nuestra página web, debe ser mayor de 18 años de edad. El usuario que proporciona datos “Duppla” mediante la presente página web declara y garantiza ser mayor de 18 años de edad, responsabilizándose enteramente de tal declaración.
                            </p>

                            <h5 class="card-title">¿Qué medidas adoptamos para proteger sus datos personales?</h5>
                            <p class="text-muted">
                                En respuesta a la preocupación de “Duppla” por garantizar la seguridad y confidencialidad de sus datos, se han adoptado los niveles de seguridad requeridos para la protección de los datos personales y se han instalado los medios técnicos a su alcance para evitar la pérdida, mal uso, alteración, acceso no autorizado y robo de los datos personales facilitados a través del Sitio Web. En todo caso, debe tener en consideración que las medidas de seguridad en Internet no son inexpugnables.
                            </p>

                            <h5 class="card-title">¿Cuáles son sus derechos sobre sus datos personales?</h5>
                            <p class="text-muted">
                                “Duppla” le informa que tiene derecho a obtener confirmación sobre si estamos tratando datos personales que le conciernen o no.<br/>
                                Del mismo modo, “Duppla” le informa que cuenta con los siguientes derechos sobre sus datos personales:<br/><br/>
                                <ul>
                                    <li>
                                        <b class="text-primary ">Acceder a sus datos:</b> Tiene derecho a acceder a sus datos para conocer qué datos personales estamos tratando que le conciernen.
                                    </li>
                                    <li>
                                        <b class="text-primary ">Solicitar la rectificación de sus datos:</b> En determinadas circunstancias, tiene derecho a rectificar aquellos datos personales que considere inexactos y que le conciernan, y que sean objeto de tratamiento por parte de “Duppla”.
                                    </li>
                                    <li>
                                        <b class="text-primary ">Cancelación de datos: </b>podrá pedir la anulación de la información existente en las bases de datos para cesar el tratamiento de los mismos, cuando, entre otros motivos, los datos ya no fueran necesarios para los fines que fueron recogidos. Para esta acción, primero realizarán el bloqueo de los datos por un periodo determinado, y posteriormente, los suprimirán.
                                    </li>
                                    <li>
                                        <b class="text-primary ">Oponerse al tratamiento de sus datos:</b> En determinadas circunstancias y por motivos relacionados con su situación particular, tendrá derecho a oponerse al tratamiento de sus datos en cuyo caso, dejaríamos de tratarlos salvo por motivos legítimos imperiosos o por el ejercicio o la defensa de posibles reclamaciones.
                                    </li>
                                </ul>
                            </p>

                            <h5 class="card-title">¿Cómo puede acceder, rectificar o cancelar sus datos personales, u oponerse a su uso? </h5>
                            <p class="text-muted">
                                Usted tiene derecho a conocer qué datos personales tenemos de usted, para qué los utilizamos y las condiciones del uso que les damos (Acceso). Asimismo, es su derecho solicitar la corrección de su información personal en caso de que esté desactualizada, sea inexacta o incompleta (Rectificación); que la eliminemos de nuestros registros o bases de datos cuando considere que la misma no está siendo utilizada adecuadamente (Cancelación); así como oponerse al uso de sus datos personales para fines específicos (Oposición). Estos derechos se conocen como derechos ARCO.<br/><br/>
                                Para el ejercicio de cualquiera de los derechos ARCO, usted deberá presentar la solicitud que corresponda al derecho que desea ejercer, a través de correo electrónico. <br/><br/>
                                Con relación al procedimiento y requisitos para el ejercicio de sus derechos ARCO, le informamos lo siguiente:<br/><br/>
                                <ol>
                                    <li>
                                        ¿A través de qué medios pueden acreditar su identidad el titular y, en su caso, su representante legal, así como la personalidad de este último?<br/>
                                        A través de correo electrónico, enviando una copia de la identificación del titular, y en su caso del representante legal, así como el poder notarial de este último.
                                    </li>
                                    <li>
                                        ¿Qué información y/o documentación deberá contener la solicitud?<br/>
                                        La solicitud deberá contener los motivos por los que desea ejercer sus Derechos ARCO.
                                    </li>
                                </ol>
                                <b class="text-primary ">En caso de tratarse de Rectificación de datos,</b> deberá señalar que información se pretende corregir. Dicha solicitud deberá estar debidamente firmada por el titular, o en su caso, por su representante legal, y mencionar la dirección de correo electrónico de contacto, a través de la cual se comunicará la respuesta. <br/><br/>

                                Deberá acompañar su solicitud, con la identificación del titular, y si el titular es una persona moral, se acompañará con el acta constitutiva de la sociedad, poder del representante legal y copia de la identificación oficial del representante.<br/><br/>
                                <ol>
                                    <li>
                                        ¿En cuántos días le daremos respuesta a su solicitud?<br/>
                                        Cinco (5) días hábiles posteriores, contados a partir de la fecha en que realizó su solicitud.
                                    </li>
                                    <li>
                                        ¿Por qué medio le comunicaremos la respuesta a su solicitud?<br/>
                                        A través de correo electrónico.
                                    </li>
                                    <li>
                                        ¿En qué medios se pueden reproducir los datos personales que, en su caso, solicite?<br/>
                                        Copia simple, CD y Memoria USB.
                                    </li>
                                </ol>
                                <br/>
                                Los datos de contacto de la persona, que está a cargo de dar trámite a las solicitudes de derechos ARCO, son los siguientes: <br/><br/>
                                <ol>
                                    <li>Nombre de la persona del departamento de datos personales: [*]</li>
                                    <li>Domicilio: [*]</li>
                                    <li>Correo electrónico: <a href="mailto:privacidad@duppla.doctor" target="_blank">privacidad@duppla.doctor</a></li>
                                    <li>Número telefónico: [*]</li>
                                    <li>Otro dato de contacto: [*]</li>
                                </ol>
                            </p>

                            <h5 class="card-title">Usted puede revocar su consentimiento para el uso de sus datos personales</h5>
                            <p class="text-muted">
                                Usted puede revocar el consentimiento que, en su caso, nos haya otorgado para el tratamiento de sus datos personales. <br/>
                                Sin embargo, es importante que tenga en cuenta que no en todos los casos podremos atender su solicitud o concluir el uso de forma inmediata, ya que es posible que por alguna obligación legal requiramos seguir tratando sus datos personales. <br/>
                                Asimismo, deberá considerar que, para ciertos fines, la revocación del consentimiento puede implicar que no le podamos seguir prestando el servicio solicitado, o la terminación de la relación con “Duppla”. <br/>
                                Para revocar su consentimiento deberá presentar su solicitud a través de correo electrónico, dirigido a: <a href="mailto:privacidad@duppla.doctor" target="_blank">privacidad@duppla.doctor</a> o enviando la solicitud de revocación debidamente firmada por el titular o su representante legal, a la dirección de nuestras oficinas. <br/><br/>
                                Con relación al procedimiento y requisitos para la revocación de su consentimiento, le informamos lo siguiente:<br/><br/>
                                <ol>
                                    <li>
                                        ¿A través de qué medios pueden acreditar su identidad el titular y, en su caso, su representante, así como la personalidad este último?<br/>
                                        A través de correo electrónico.
                                    </li>
                                    <li>
                                        ¿Qué información y/o documentación deberá contener la solicitud?<br/>
                                        La solicitud deberá contener los motivos por cuáles revoca su consentimiento al tratamiento de sus datos personales, y contar con firma autógrafa del titular, o en su caso de su representante legal, además debe establecer un correo electrónico de contacto. Dicha solicitud deberá estar acompañada de la identificación oficial del titular, o en su caso, del acta constitutiva de la sociedad, poder notarial y copia de la identificación oficial del representante legal.
                                    </li>
                                    <li>
                                        ¿En cuántos días le daremos respuesta a su solicitud?<br/>
                                        Cinco (5) días hábiles posteriores, contados a partir de la fecha en que realizó su solicitud.
                                    </li>
                                    <li>
                                        ¿Por qué medio le comunicaremos la respuesta a su solicitud?<br/>
                                        Por medio del correo electrónico, a la dirección de correo establecida en su solicitud.
                                    </li>
                                </ol>
                            </p>

                            <h5 class="card-title">¿Cómo puede limitar el uso o divulgación de su información personal?</h5>
                            <p class="text-muted">
                                Con objeto de que usted pueda limitar el uso y divulgación de su información personal, le ofrecemos la dirección de correo electrónico: <a href="mailto:privacidad@duppla.doctor" target="_blank">privacidad@duppla.doctor</a> para que través de ese medio nos contacte, y “Duppla” pueda llevar a cabo los fines antes mencionados.
                            </p>

                        </div>
                    </div>
                </div>
                <!--end col-->
            </div>
            <!--end row-->
        </div>
        <!--end container-->
    </section>
    <!--end section-->
    <!-- End Terms & Conditions -->
    <!--end section-->
    <Footer />
    <!-- Footer End -->
    <!--Switcher /-->
    <!-- Back to top -->
    <a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" v-scroll-to="'#topnav'">
        <arrow-up-icon class="icons"></arrow-up-icon>
    </a>
    <!-- Back to top -->
</div>
</template>
